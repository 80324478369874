import { ActionType, getType } from "typesafe-actions"

import * as actions from "../actions/patient"
import {
  FilterState,
  ActivePatientResult,
  PatientListResult,
  PatientMatch,
  PatientListSorting,
  SetPositioning,
} from "../types/patientTypes"
import { filterSections } from "pages/Patients/PatientList/filterSections"
import { ResultStatus } from "../types/dataStructureTypes"
import { updatedRadiographSetPositions } from "library/utilities/patients"

type PatientState = Readonly<{
  patientListResult: PatientListResult
  activePatientResult: ActivePatientResult
  filterValues: FilterState[]
  isPatientMatchingMode: boolean
  patientMatch: PatientMatch | null
  patientListSorting: PatientListSorting | null
}>
export const initialFilters = filterSections.map((s) => ({
  section: s.heading.value,
  selected: [],
  match: s.match,
}))

const initialState: PatientState = {
  patientListResult: {
    resultStatus: ResultStatus.none,
    patientList: [],
  },
  activePatientResult: {
    resultStatus: ResultStatus.none,
    images: null,
    patient: null,
    links: [],
    radiographSets: [],
    activeRadiographSet: null,
    activePatientListItem: null,
    isAccordionOpen: false,
    isEditSetActive: false,
  },
  filterValues: initialFilters,
  isPatientMatchingMode: false,
  patientMatch: null,
  patientListSorting: { key: "imageDate", sortDirection: "DESC" },
}

type PatientActions = ActionType<typeof actions>

export default (state = initialState, action: PatientActions): PatientState => {
  switch (action.type) {
    case getType(actions.setInitialState): {
      return {
        ...initialState,
        patientMatch: state.patientMatch,
        isPatientMatchingMode: state.isPatientMatchingMode,
        patientListSorting: state.patientListSorting,
      }
    }
    case getType(actions.setPatientListResult): {
      return {
        ...state,
        patientListResult: action.payload,
      }
    }
    case getType(actions.setPatientListResultStatus): {
      return {
        ...state,
        patientListResult: {
          ...state.patientListResult,
          resultStatus: action.payload,
        },
      }
    }

    case getType(actions.setActivePatientResult): {
      return {
        ...state,
        activePatientResult: action.payload,
      }
    }

    case getType(actions.clearActivePatientResult): {
      return {
        ...state,
        activePatientResult: {
          ...state.activePatientResult,
          resultStatus: ResultStatus.none,
          images: null,
          patient: null,
          links: [],
          radiographSets: [],
          activeRadiographSet: null,
          activePatientListItem: null,
        },
      }
    }

    case getType(actions.setActivePatientResultStatus): {
      return {
        ...state,
        activePatientResult: {
          ...state.activePatientResult,
          resultStatus: action.payload,
        },
      }
    }
    case getType(actions.setPatientResultStatus): {
      const patient = state.activePatientResult.patient

      return {
        ...state,
        activePatientResult: {
          ...state.activePatientResult,
          ...(patient && {
            patient: {
              ...patient,
              resultStatus: action.payload,
            },
          }),
        },
      }
    }

    case getType(actions.setConfirmedAnnotations): {
      return {
        ...state,
        activePatientResult: {
          ...state.activePatientResult,
          images:
            state.activePatientResult.images?.map((image) =>
              image.resultId ===
              state.activePatientResult.activePatientListItem?.id
                ? {
                    ...image,
                    confirmedAnnotations: action.payload,
                  }
                : image
            ) || [],
        },
      }
    }
    case getType(actions.setActivePatientListItem): {
      return {
        ...state,
        activePatientResult: {
          ...state.activePatientResult,
          activePatientListItem: action.payload,
        },
      }
    }

    case getType(actions.toggleAccordion): {
      return {
        ...state,
        activePatientResult: {
          ...state.activePatientResult,
          isAccordionOpen: action.payload,
        },
      }
    }

    case getType(actions.deleteActivePatientXray): {
      return {
        ...state,
        activePatientResult: {
          ...state.activePatientResult,
          images:
            state.activePatientResult.images?.filter(
              (a) => a.resultId !== action.payload
            ) || [],
        },
      }
    }
    case getType(actions.setFilterValues): {
      return {
        ...state,
        filterValues: action.payload,
      }
    }
    case getType(actions.setIsPatientMatchingMode): {
      return {
        ...state,
        isPatientMatchingMode: action.payload,
      }
    }
    case getType(actions.setPatientMatch): {
      return {
        ...state,
        patientMatch: action.payload,
      }
    }
    case getType(actions.setPatientListSorting): {
      return {
        ...state,
        patientListSorting: action.payload,
      }
    }
    case getType(actions.setActiveRadiographSet): {
      return {
        ...state,
        activePatientResult: {
          ...state.activePatientResult,
          activeRadiographSet: action.payload,
        },
      }
    }
    case getType(actions.replaceRadiographSetImage): {
      const { position, resultId } = action.payload

      const activeRadiographSet = state.activePatientResult.activeRadiographSet
      const positions = activeRadiographSet?.positions
      const changes = activeRadiographSet?.changes || []
      const currentReplacingImage = activeRadiographSet?.activeSetImage

      if (!positions || !currentReplacingImage) return state

      // Update change if it exists, else add a new change
      const updatedChanges = changes.some((a) => a.position === position)
        ? changes.map((a) =>
            a.position === position
              ? {
                  ...a,
                  resultId: resultId,
                }
              : a
          )
        : [...changes, { position, resultId }]

      /*
        We need to update the radiographs and positions array with the newly added image id.
        If the image is replaced, we need to remove the old image id from the positions array.
      */

      const newlyUpdatedRadiographs = () => {
        const changedPositions = new Set(updatedChanges.map((c) => c.position))
        const changedResultIds = new Set(updatedChanges.map((c) => c.resultId))

        const newPositions: SetPositioning[] = updatedChanges

        // Add positions that do not match any changed resultIds or positions
        positions.forEach((p) => {
          if (
            !changedResultIds.has(p.resultId) &&
            !changedPositions.has(p.position) &&
            currentReplacingImage.resultId !== p.resultId
          ) {
            newPositions.push(p)
          }
        })

        return newPositions
      }

      return {
        ...state,
        activePatientResult: {
          ...state.activePatientResult,
          ...(activeRadiographSet && {
            activeRadiographSet: {
              ...activeRadiographSet,
              radiographs: newlyUpdatedRadiographs().map((r) => r.resultId),
              positions: newlyUpdatedRadiographs(),
              ...(activeRadiographSet.positions && {
                changes: updatedChanges,
              }),
            },
          }),
        },
      }
    }
    case getType(actions.setPatientMetadata): {
      return {
        ...state,
        activePatientResult: {
          ...state.activePatientResult,
          ...action.payload,
        },
      }
    }

    // Set the metadata for a specific image within images
    case getType(actions.setPatientImageMeta): {
      const { id, meta } = action.payload
      const images = state.activePatientResult.images

      return {
        ...state,
        activePatientResult: {
          ...state.activePatientResult,
          ...(images && {
            images: images.map((i) =>
              i.resultId === id ? { ...i, imageMetadata: { ...meta } } : i
            ),
          }),
        },
      }
    }
    case getType(actions.setActiveSetImage): {
      const activeRadiographSet = state.activePatientResult.activeRadiographSet
      return {
        ...state,
        activePatientResult: {
          ...state.activePatientResult,
          ...(activeRadiographSet && {
            activeRadiographSet: {
              ...activeRadiographSet,
              activeSetImage: action.payload,
            },
          }),
        },
      }
    }
    case getType(actions.setActiveSetImageId): {
      const activeRadiographSet = state.activePatientResult.activeRadiographSet
      return {
        ...state,
        activePatientResult: {
          ...state.activePatientResult,
          ...(activeRadiographSet?.activeSetImage && {
            activeRadiographSet: {
              ...activeRadiographSet,
              activeSetImage: {
                ...activeRadiographSet.activeSetImage,
                resultId: action.payload,
              },
            },
          }),
        },
      }
    }
    case getType(actions.setIsEditSetActive): {
      return {
        ...state,
        activePatientResult: {
          ...state.activePatientResult,
          isEditSetActive: action.payload,
        },
      }
    }
    case getType(actions.updateRadiographPosition): {
      const { currentRadiograph, newPosition } = action.payload
      const activeRadiographSet = state.activePatientResult.activeRadiographSet

      function updatedChanges(): SetPositioning[] {
        let changes = activeRadiographSet?.changes || []
        const positions = activeRadiographSet?.positions || []

        const findPositionByPosition = (position: number) =>
          positions.find((p) => p.position === position)

        const findChangeByPosition = (position: number) => {
          return changes.find((change) => change.position === position)
        }

        const findChangeByResultId = (resultId: string) => {
          return changes.find((change) => change.resultId === resultId)
        }

        const changeAtNewPosition = findChangeByPosition(newPosition)

        const updateChanges = (
          comparePosition: number,
          addedPosition: number
        ) =>
          changes.map((c) =>
            c.position === comparePosition
              ? { ...c, position: addedPosition }
              : c
          )

        const newlyAddedChangeWithResultId = {
          position: currentRadiograph.position,
          resultId: findPositionByPosition(newPosition)?.resultId || "",
        }

        const newlyAddedChange = {
          resultId: currentRadiograph.resultId,
          position: newPosition,
        }

        // Check if the new position is occupied by an image
        const imageInPosition =
          changeAtNewPosition ||
          !findChangeByResultId(
            findPositionByPosition(newPosition)?.resultId || ""
          )

        // If new position is an empty spot
        if (!imageInPosition) {
          if (findChangeByResultId(currentRadiograph.resultId)) {
            changes = updateChanges(currentRadiograph.position, newPosition)
          } else {
            changes.push(newlyAddedChange)
          }
          // If new position is already occupied by another image
        } else {
          // If new position is in changes
          if (changeAtNewPosition) {
            changes = updateChanges(newPosition, currentRadiograph.position)
            changes = findChangeByResultId(currentRadiograph.resultId)
              ? changes.map((c) =>
                  c.resultId === currentRadiograph.resultId
                    ? { ...c, position: newPosition }
                    : c
                )
              : changes.concat(newlyAddedChange)
          }
          // If current position is in changes
          else if (findChangeByPosition(currentRadiograph.position)) {
            changes = updateChanges(currentRadiograph.position, newPosition)
            changes = changes.concat(newlyAddedChangeWithResultId)
          } else {
            // If neither the current position nor the new position is in changes
            changes.push(newlyAddedChange, newlyAddedChangeWithResultId)
          }
        }
        return changes.filter((c) => c.resultId)
      }

      return {
        ...state,
        activePatientResult: {
          ...state.activePatientResult,
          ...(activeRadiographSet && {
            activeRadiographSet: {
              ...activeRadiographSet,
              changes: updatedChanges(),
              activeSetImage:
                updatedChanges().find((a) => a.position === newPosition) ||
                null,
            },
          }),
        },
      }
    }
    case getType(actions.removeRadiographFromSet): {
      const filteredRadiographs = (radiographs?: SetPositioning[] | null) =>
        radiographs?.filter((r) => r.resultId !== action.payload) || []
      const activeRadiographSet = state.activePatientResult.activeRadiographSet

      return {
        ...state,
        activePatientResult: {
          ...state.activePatientResult,
          ...(activeRadiographSet && {
            activeRadiographSet: {
              ...activeRadiographSet,
              changes: filteredRadiographs(activeRadiographSet?.changes),
              positions: filteredRadiographs(activeRadiographSet?.positions),
              radiographs: activeRadiographSet.radiographs.filter(
                (r) => r !== action.payload
              ),
              activeSetImage:
                // Set the next image as the active image
                updatedRadiographSetPositions(activeRadiographSet)[1],
            },
          }),
        },
      }
    }

    default:
      return state
  }
}
