import { ActionType, getType } from "typesafe-actions"

import * as actions from "library/common/actions/features"
import { RegulatoryMarket } from "../types/serverDataTypes"
import { SHOW_NEW_TOOTH_MAP } from "library/utilities/constants"

export type FeaturesState = Readonly<{
  featureNervus: boolean
  featureNerveCanal: boolean
  featureCalculus: boolean
  featureBoneloss: boolean
  featureDrawingMode: boolean
  featureFmx: boolean
  showInactivePblButton: boolean
  revertVersion: boolean
  showPdfVersion: boolean
  featureImpacted: boolean
  featurePblLandmarks: boolean
  hidePblClassification: boolean
  showFullPblForm: boolean
  featureCopyToClipboard: boolean
  featureShowSSO: boolean
  featureShowIteroLogin: boolean
  featureCoachMarks: boolean
  showThemeTestButtons: boolean
  featureWhatsNew: boolean
  featureCariesProForPeri: boolean
  featurePerioProForPeri: boolean
  featureDrawingModeInPeri: boolean
  featureHsmInPeri: boolean
  showSetupInOnboarding: boolean
  featureShowTimeOut: boolean
  featureCroppedTeeth: boolean
  featureCalculusOnPeri: boolean
  featureShowNotes: boolean
  featurePatientFileConfirmedAnnotations: boolean
  featureShowOrthoWarning: boolean
  featureAlfaDocsIntegration: boolean
  featureRememberHsm: boolean
  featureDynamicPbl: boolean
  regulatoryMarket: RegulatoryMarket
  manualVersion: string
  manualLanguages: string[]
  mirthInstaller: string
  featureUploadFromPatientFile: boolean
}>

export const initialFeaturesState: FeaturesState = {
  featureNervus: !!process.env.REACT_APP_FEATURE_NERVUS,
  featureNerveCanal: !!process.env.REACT_APP_FEATURE_NERVE_CANAL,
  featureCalculus: !!process.env.REACT_APP_FEATURE_CALCULUS,
  featureBoneloss: !!process.env.REACT_APP_FEATURE_BONE_LOSS,
  featureDrawingMode: !!process.env.REACT_APP_FEATURE_DRAWING_MODE,
  featureFmx: !!process.env.REACT_APP_FEATURE_FMX,
  showInactivePblButton: !!process.env.REACT_APP_SHOW_INACTIVE_PBL_BUTTON,
  revertVersion: !!process.env.REACT_APP_REVERT_VERSION,
  showPdfVersion: !!process.env.REACT_APP_SHOW_PDF_VERSION,
  featureImpacted: !!process.env.REACT_APP_FEATURE_IMPACTED,
  featurePblLandmarks: !!process.env.REACT_APP_SHOW_PBL_LANDMARKS,
  hidePblClassification: !!process.env.REACT_APP_HIDE_PBL_CLASSIFICATION,
  showFullPblForm: !!process.env.REACT_APP_SHOW_FULL_PBL_FORM,
  featureCopyToClipboard: !!process.env.REACT_APP_COPY_TO_CLIPBOARD,
  featureShowSSO: !!process.env.REACT_APP_SHOW_SSO,
  featureShowIteroLogin: !!process.env.REACT_APP_SHOW_ITERO_LOGIN,
  featureCoachMarks: !!process.env.REACT_APP_SHOW_COACH_MARKS,
  showThemeTestButtons: !!process.env.REACT_APP_SHOW_THEME_TEST_BUTTONS,
  featureWhatsNew: !!process.env.REACT_APP_SHOW_WHATS_NEW,
  featureCariesProForPeri: !!process.env.REACT_APP_SHOW_CARIES_PRO_FOR_PERI,
  featurePerioProForPeri: !!process.env.REACT_APP_SHOW_PERIO_PRO_FOR_PERI,
  featureDrawingModeInPeri: !!process.env.REACT_APP_SHOW_DRAWING_MODE_IN_PERI,
  featureHsmInPeri: !!process.env.REACT_APP_SHOW_HSM_IN_PERI,
  showSetupInOnboarding: !!process.env.REACT_APP_SHOW_SETUP_IN_ONBOARDING,
  featureShowTimeOut: !!process.env.REACT_APP_SHOW_TIME_OUT,
  featureCroppedTeeth:
    !!process.env.REACT_APP_CROPPED_TEETH && SHOW_NEW_TOOTH_MAP,
  featureCalculusOnPeri: !!process.env.REACT_APP_SHOW_CALCULUS_ON_PERI,
  featureShowNotes: !!process.env.REACT_APP_SHOW_NOTES_SECTION,
  featurePatientFileConfirmedAnnotations:
    !!process.env.REACT_APP_SHOW_PATIENT_FILE_CONFIRMED_ANNOTATIONS,
  featureShowOrthoWarning: !!process.env.REACT_APP_SHOW_ORTHO_WARNING,
  featureAlfaDocsIntegration:
    !!process.env.REACT_APP_SHOW_ALFA_DOCS_INTEGRATION,
  featureRememberHsm: !!process.env.REACT_APP_REMEMBER_HSM,
  featureDynamicPbl: !!process.env.REACT_APP_SHOW_NEW_PBL,
  regulatoryMarket: process.env
    .REACT_APP_PRODUCT_INFO_TO_SHOW as RegulatoryMarket,
  manualVersion: process.env.REACT_APP_USER_MANUAL_VERSION || "",
  manualLanguages: process.env.REACT_APP_MANUAL_LANGUAGES?.split(",") || ["en"],
  mirthInstaller: process.env.REACT_APP_MIRTH_INSTALLER || "",
  featureUploadFromPatientFile:
    !!process.env.REACT_APP_UPLOAD_FROM_PATIENT_FILE,
}
type FeaturesActions = ActionType<typeof actions>

export default (
  state = initialFeaturesState,
  action: FeaturesActions
): FeaturesState => {
  switch (action.type) {
    case getType(actions.setInitialState):
      return {
        ...initialFeaturesState,
      }
    case getType(actions.updateFeatures):
      return {
        ...initialFeaturesState,
        ...action.payload,
      }

    default:
      return state
  }
}
