import { createAction } from "typesafe-actions"
import { ResultStatus } from "../types/dataStructureTypes"
import {
  patientTypes,
  FilterState,
  ActivePatientResult,
  PatientListResult,
  Patient,
  PatientMatch,
  MatchingPatient,
  PatientListSorting,
  RadiographSet,
  ConfirmedAnnotations,
  IActivePatientListItem,
  SetPositioning,
  UpdatedSetPosition,
  PatientImageMeta,
} from "../types/patientTypes"

export const getPatientsFromServer = createAction(
  patientTypes.GET_PATIENTS_FROM_SERVER
)()
export const getExternalPatient = createAction(
  patientTypes.GET_EXTERNAL_PATIENT
)<string>()
export const setPatientListResult = createAction(
  patientTypes.SET_PATIENT_LIST_RESULT
)<PatientListResult>()
export const setPatientListResultStatus = createAction(
  patientTypes.SET_PATIENT_LIST_RESULT_STATUS
)<ResultStatus>()
export const setActivePatientResultStatus = createAction(
  patientTypes.SET_ACTIVE_PATIENT_RESULT_STATUS
)<ResultStatus>()
export const setPatientResultStatus = createAction(
  patientTypes.SET_PATIENT_RESULT_STATUS
)<ResultStatus>()
export const setConfirmedAnnotations = createAction(
  patientTypes.SET_CONFIRMED_ANNOTATIONS
)<ConfirmedAnnotations>()
export const getConfirmedAnnotations = createAction(
  patientTypes.GET_CONFIRMED_ANNOTATIONS
)<string>()
export const setActivePatientResult = createAction(
  patientTypes.SET_ACTIVE_PATIENT_RESULT
)<ActivePatientResult>()
export const setActivePatientListItem = createAction(
  patientTypes.SET_ACTIVE_PATIENT_LIST_ITEM
)<IActivePatientListItem | null>()
export const requestPatient = createAction(
  patientTypes.REQUEST_PATIENT
)<string>()
export const deleteActivePatientXray = createAction(
  patientTypes.DELETE_ACTIVE_PATIENT_XRAY
)<string>()
export const setInitialState = createAction(patientTypes.SET_INITIAL_STATE)()
export const setFilterValues = createAction(patientTypes.SET_FILTER_VALUES)<
  FilterState[]
>()
export const setIsPatientMatchingMode = createAction(
  patientTypes.SET_IS_PATIENT_MATCHING_MODE
)<boolean>()
export const clearActivePatientResult = createAction(
  patientTypes.CLEAR_ACTIVE_PATIENT_RESULT
)()
export const overwritePatient = createAction(
  patientTypes.OVERWRITE_PATIENT
)<Patient>()
export const addNewPatient = createAction(
  patientTypes.ADD_NEW_PATIENT
)<Patient>()
export const deletePatient = createAction(patientTypes.DELETE_PATIENT)<string>()

export const setPatientMatch = createAction(
  patientTypes.SET_PATIENT_MATCH
)<PatientMatch | null>()
export const linkPatient = createAction(
  patientTypes.LINK_PATIENT
)<MatchingPatient>()
export const relinkPatient = createAction(
  patientTypes.RELINK_PATIENT
)<MatchingPatient>()
export const setPatientListSorting = createAction(
  patientTypes.SET_PATIENT_LIST_SORTING
)<PatientListSorting>()
export const setActiveRadiographSet = createAction(
  patientTypes.SET_ACTIVE_RADIOGRAPH_SET
)<RadiographSet | null>()
export const replaceRadiographSetImage = createAction(
  patientTypes.REPLACE_RADIOGRAPH_SET_IMAGE
)<SetPositioning>()
export const setPatientMetadata = createAction(
  patientTypes.SET_PATIENT_METADATA
)<Patient>()
export const setPatientImageMeta = createAction(
  patientTypes.SET_PATIENT_IMAGE_METADATA
)<PatientImageMeta>()
export const setActiveSetImage = createAction(
  patientTypes.SET_ACTIVE_SET_IMAGE
)<SetPositioning | null>()
export const setActiveSetImageId = createAction(
  patientTypes.SET_ACTIVE_SET_IMAGE_ID
)<string>()
export const setIsEditSetActive = createAction(
  patientTypes.SET_IS_EDIT_SET_ACTIVE
)<boolean>()
export const toggleAccordion = createAction(
  patientTypes.TOGGLE_ACCORDION
)<boolean>()
export const updateRadiographPosition = createAction(
  patientTypes.UPDATE_RADIOGRAPH_POSITION
)<UpdatedSetPosition>()
export const removeRadiographFromSet = createAction(
  patientTypes.REMOVE_RADIOGRAPH_FROM_SET
)<string>()
