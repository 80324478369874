import { takeLatest } from "redux-saga/effects"
import { closeModal } from "../actions/modal"
import { history } from "core/store/configureStore"
import { ModalTypes } from "../types/modalTypes"

function* closeModalSaga({ payload }: ReturnType<typeof closeModal>) {
  if (!!payload) {
    history.push(`/patients/${payload}`)
  }
}

export default function* modalSaga() {
  yield takeLatest(ModalTypes.CLOSE_MODAL, closeModalSaga)
}
